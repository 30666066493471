import { Injectable } from '@angular/core';
import { BrandTableData } from '../data/brand-table';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Brand } from '../../Brand';

@Injectable()
export class BrandTableService extends BrandTableData {

  private websitesUrl = "https://www.geoloc-systems.com/api/brands";

  constructor(private http: HttpClient) { super() }

  getData(): Observable<Brand[]> {
    return this.http.get<Brand[]>(this.websitesUrl);
  }
}
