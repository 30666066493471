import { Injectable } from '@angular/core';
import { CountryTableData } from '../data/country-table';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Country } from '../../country';

@Injectable()
export class CountryTableService extends CountryTableData {

  private countryUrl = "https://www.geoloc-systems.com/api/countries";

  constructor(private http: HttpClient) { super() }

  getData(): Observable<Country[]> {
    return this.http.get<Country[]>(this.countryUrl);
  }
}
