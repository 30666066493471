import { Injectable } from '@angular/core';
import { PrestataireTableData } from '../data/prestataire-table';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Prestataire } from '../../prestataire';

@Injectable()
export class PrestataireTableService extends PrestataireTableData {

  private pUrl = "https://www.geoloc-systems.com/api/prestataires";
  private users = [];

  constructor(private http: HttpClient) { super() }

  getData(): Observable<Prestataire[]> {
    return this.http.get<Prestataire[]>(this.pUrl);
  }
}
