import { Injectable } from '@angular/core';
import { CheckTableData } from '../data/check-table';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Website } from '../../website';

@Injectable()
export class CheckTableService extends CheckTableData {

  private checkURL = "https://www.geoloc-systems.com/api/websites/checked";
  private acceptURL = "https://www.geoloc-systems.com/api/websites/accept";
  private websitesURL = "https://www.geoloc-systems.com/api/websites";

  constructor(private http: HttpClient) { super() }

  getData(): Observable<Website[]> {
    return this.http.get<Website[]>(this.checkURL);
  }

  handle(event) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    if (event.action == 'accept') {
      let body = encodeURIComponent("id") + '=' + encodeURIComponent(event.data.id);
      console.log(body);
      this.http.put(this.acceptURL, body, {headers: headers})
        .subscribe(data => console.log(data));
    } else if (event.action == 'deny') {
      let body = encodeURIComponent("id") + '=' + encodeURIComponent(event.data.id);
      this.http.delete(this.websitesURL, {headers: headers, body: body})
        .subscribe(data => console.log(data));
    }
  }
}
