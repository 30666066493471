import { Injectable } from '@angular/core';
import { RulesTableData } from '../data/rules-table';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Rule } from '../../rule';
import { Brand } from '../../Brand';

@Injectable()
export class RulesTableService extends RulesTableData {

  private rulesUrl = "https://www.geoloc-systems.com/api/rules";

  constructor(private http: HttpClient) { super() }

  getData(): Observable<Rule[]> {
    return this.http.get<Rule[]>(this.rulesUrl);
  }
}
