import { Injectable } from '@angular/core';
import { WebsiteTableData } from '../data/website-table';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Website } from '../../website';
import { Brand } from '../../Brand';

@Injectable()
export class WebsiteTableService extends WebsiteTableData {

  private websitesUrl = "https://www.geoloc-systems.com/api/websites";

  constructor(private http: HttpClient) { super() }

  getData(): Observable<Website[]> {
    return this.http.get<Website[]>(this.websitesUrl);
  }
}
